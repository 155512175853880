import React from "react";
import "./index.scss";
import ScrollLink from "../ScrollLink";

const LinkSampleReport = (props) => {
  const { isReportDealsPage, isScrollLink, refParameter } = props;
  const link = isReportDealsPage
    ? "https://amzsc.amzgb.com/goto/?url=https://downloads.ctfassets.net/0yu6ph4occja/5E1b8Z9QxCkiF3mIItbVaL/136c2505e59658cf5f009f4c49b51045/SAMPLE_Report.pdf"
    : "https://amzsc.amzgb.com/goto/?url=https://assets.ctfassets.net/0yu6ph4occja/1Ka4f2FMyACAQdNcK2hf0V/2dc7deb81e84c6bc748d3254942bf743/SAMPLE_Report_EN.pdf";

  return (
    <div className="linkSampleReport">
      Check out the{" "}
      {isScrollLink ? (
        <ScrollLink refParameter={refParameter}>Sample Report</ScrollLink>
      ) : (
        <a href={link} target="_blank">
          Sample Report
        </a>
      )}{" "}
      before ordering
    </div>
  );
};

export default LinkSampleReport;
